import { call, put, takeLatest, select } from "redux-saga/effects";
import { PaginatedResult, RequestError } from "../../api/types";
import {
  RequestDataAction,
  REQUEST_DATA,
  UpdatePostingDocumentDateAction,
  UPDATE_POSTED_DATE,
  DeleteInvoiceAction,
  DELETE_INVOICE,
  SelfBillListItem,
  UPDATE_OVERRIDES,
  UpdateSelfBillListOverridesAction,
} from "./types";
import {
  getSelfBillList,
  postUpdatePostingDocumentDate,
  postUpdateSelfBillOverrides,
} from "../../api/invoice/self-bill-list";
import { deleteInvoice } from "../../api/invoice/delete";
import {
  setSelfBillListData,
  setSelfBillListError,
  setUpdateDatePostedError,
  requestSelfBillList,
  setUpdateSelfBillListOverrideError,
} from "./actions";
import { RootState } from "../../store/rootReducer";

function* fetchSelfBillList(action: RequestDataAction) {
  try {
    const data: PaginatedResult<SelfBillListItem> = yield call(
      getSelfBillList,
      action.payload.params
    );
    const items: SelfBillListItem[] = data.items.map((i) => ({
      ...i,
      checked: false,
    }));
    yield put(setSelfBillListData({ ...data, items: items }));
  } catch (e) {
    let error: RequestError = e as any;
    yield put(setSelfBillListError(error));
  }
}

function* updatePostingDocumentDate(action: UpdatePostingDocumentDateAction) {
  try {
    yield call(
      postUpdatePostingDocumentDate,
      action.payload.invoiceId,
      action.payload.datePosted
    );

    const { selfBillList } = yield select((state: RootState) => state);
    const { tableControls, filterParams } = selfBillList;

    if (tableControls || filterParams) {
      yield put(requestSelfBillList({ tableControls, filterParams }));
    } else {
      yield put(
        requestSelfBillList({
          tableControls: {
            pageNumber: 1,
            pageSize: 25,
            sortDirection: 1,
            sortBy: "invoiceId",
          },
          filterParams: {
            territoryCode: undefined,
            partnerCode: undefined,
            datePosted: undefined,
            partnerVatStatusQuery: undefined,
            partnerBlocked: undefined,
          },
        })
      );
    }
  } catch (e) {
    const error = e as RequestError;
    yield put(setUpdateDatePostedError(error));
  }
}

function* deleteSelfBillInvoiceFromList(action: DeleteInvoiceAction) {
  console.log("try delete invoice - sag");
  try {
    console.log("calling from try block - saga");
    yield call(deleteInvoice, action.payload.invoiceId);

    const { selfBillList } = yield select((state: RootState) => state);
    const { tableControls, filterParams } = selfBillList;

    if (tableControls || filterParams) {
      yield put(requestSelfBillList({ tableControls, filterParams }));
    } else {
      yield put(
        requestSelfBillList({
          tableControls: {
            pageNumber: 1,
            pageSize: 25,
            sortDirection: 1,
            sortBy: "invoiceId",
          },
          filterParams: {
            territoryCode: undefined,
            partnerCode: undefined,
            datePosted: undefined,
            partnerVatStatusQuery: undefined,
            partnerBlocked: undefined,
          },
        })
      );
    }
  } catch (e) {
    let error: RequestError = e as any;
    yield put(setSelfBillListError(error));
  }
}

function* updateSelfBillOverrides(action: UpdateSelfBillListOverridesAction) {
  try {
    yield call(
      postUpdateSelfBillOverrides,
      action.payload.invoiceId,
      action.payload.params
    );

    const { selfBillList } = yield select((state: RootState) => state);
    const { tableControls, filterParams } = selfBillList;

    if (tableControls || filterParams) {
      yield put(requestSelfBillList({ tableControls, filterParams }));
    } else {
      yield put(
        requestSelfBillList({
          tableControls: {
            pageNumber: 1,
            pageSize: 25,
            sortDirection: 1,
            sortBy: "invoiceId",
          },
          filterParams: {
            territoryCode: undefined,
            partnerCode: undefined,
            datePosted: undefined,
            partnerVatStatusQuery: undefined,
            partnerBlocked: undefined,
          },
        })
      );
    }
  } catch (e) {
    const error = e as RequestError;
    yield put(setUpdateSelfBillListOverrideError(error));
  }
}

export function* watchSelfBillList() {
  yield takeLatest(REQUEST_DATA, fetchSelfBillList);
}

export function* watchUpdatePostingDocumentDate() {
  yield takeLatest(UPDATE_POSTED_DATE, updatePostingDocumentDate);
}

export function* watchDeleteSelfBillInvoiceFromList() {
  yield takeLatest(DELETE_INVOICE, deleteSelfBillInvoiceFromList);
}

export function* watchUpdateSelfBillOverrides() {
  yield takeLatest(UPDATE_OVERRIDES, updateSelfBillOverrides);
}
