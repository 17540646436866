import { makeStyles } from "tss-react/mui";
import { Box, Button, Card, Modal, Typography } from "@mui/material";
import React from "react";

type ConfirmationModalProps = {
  open: boolean;
  title: string;
  text: string;
  confirmButtonText?: string;
  onClose: () => void;
  onConfirm: () => void;
};

const useStyles = makeStyles()((theme) => ({
  container: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    outline: 0,
  },
  card: {
    padding: theme.spacing(2),
    width: 500,
  },
  buttonCancel: {
    marginRight: theme.spacing(0.5),
  },
}));

export const ConfirmationModal = ({
  open,
  text,
  title,
  confirmButtonText,
  onClose,
  onConfirm,
}: ConfirmationModalProps) => {
  const { classes } = useStyles();

  return (
    <Modal open={open} onClose={onClose}>
      <Box className={classes.container}>
        <Card className={classes.card}>
          <Box mt={2} mb={1}>
            <Typography variant="h2" align="center">
              {title}
            </Typography>
            <Box display="flex" justifyContent="center">
              <p>{text}</p>
            </Box>
          </Box>
          <Box display="flex" flexDirection="row-reverse">
            <Button
              color="primary"
              size="large"
              variant="contained"
              type="submit"
              onClick={onConfirm}
            >
              {confirmButtonText ?? "Confirm"}
            </Button>
            <Button
              size="large"
              variant="outlined"
              onClick={onClose}
              className={classes.buttonCancel}
            >
              Cancel
            </Button>
          </Box>
        </Card>
      </Box>
    </Modal>
  );
};
