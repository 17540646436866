import { LoaderButton } from "../../shared/components/LoaderButton";
import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { fetchExternalPartner } from "../../api/partner/fetch-external-partner";
import { APIFetchingWrapper } from "../../shared/components/APIFetchingWrapper";
import { requestDetailPartnerData } from "../../store/detailPartners/actions";
import { RootState } from "../../store/rootReducer";
import { PartnerCard } from "./components/PartnerCard";

type Props = { title: string };

export const ViewPartner = ({ title }: Props) => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const partnerId = Number(id);

  const data = useSelector(
    (state: RootState) => state.detailPartners.items[partnerId]
  );

  useEffect(() => {
    if (!data && !isNaN(partnerId)) {
      dispatch(requestDetailPartnerData(partnerId));
    }
  }, [data, partnerId, dispatch]);

  const fetching = useSelector((state: RootState) =>
    state.detailPartners.fetchingIds.includes(partnerId)
  );

  const error = useSelector((state: RootState) =>
    state.detailPartners.errors.find((error) => error.id === partnerId)
  );

  const handleRefresh = () => {
    setLoading(true);
    fetchExternalPartner(data.thirdPartyId)
      .then(() => {
        dispatch(requestDetailPartnerData(partnerId));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleNavToQueryPartners = () => {
    history.push(`/partners`);
  };

  return (
    <React.Fragment>
      <Box display="flex" justifyContent="space-between" alignItems="baseline">
        <Typography variant="h2" gutterBottom>
          {`${title}: ${data?.partnerName || ""}`}
        </Typography>
        <Box display="flex" justifyContent="space-between">
          <Box marginRight={1}>
            <Button
              size="large"
              color="primary"
              variant="outlined"
              onClick={handleNavToQueryPartners}
            >
              Back To Partners
            </Button>
          </Box>

          <LoaderButton
            color="primary"
            variant="contained"
            loading={loading}
            disabled={loading}
            onClick={handleRefresh}
          >
            Refresh
          </LoaderButton>
        </Box>
      </Box>
      <APIFetchingWrapper loading={fetching} error={error}>
        {data && <PartnerCard data={data} />}
      </APIFetchingWrapper>
    </React.Fragment>
  );
};
