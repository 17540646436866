import React, { useRef, useState } from "react";
import {
  Box,
  Button,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Tooltip,
  ButtonGroup,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  Card,
  Typography,
} from "@mui/material";
import { ArrowDropDown } from "@mui/icons-material";
import { includes, toArray } from "lodash";
import pickBy from "lodash/pickBy";
import { makeStyles } from "tss-react/mui";
import { DraggableModal } from "../../../shared/components/DraggableModal";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/rootReducer";
import { SelfBillListItem } from "../../../store/selfBillList/types";
import { LoaderButton } from "../../../shared/components/LoaderButton";
import { APIFetchingWrapper } from "../../../shared/components/APIFetchingWrapper";
import { updateSelfBillListOverride } from "../../../store/selfBillList/actions";

enum Option {
  VAT = "VAT Status",
  Blocked = "Blocked",
}

const useStyles = makeStyles()((theme) => ({
  card: {
    padding: theme.spacing(2),
    width: 500,
  },
  buttonCancel: {
    marginRight: theme.spacing(0.5),
  },
}));

export default function OverrideMenu() {
  const { classes } = useStyles();

  const { items, checked, error } = useSelector(
    (state: RootState) => state.selfBillList
  );

  const [open, setOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<string[]>([]);
  const [openOverrideModal, setOpenOverrideModal] = useState<boolean>(false);
  const [openPostInvoiceModal, setOpenPostInvoiceModal] = useState<boolean>(
    false
  );
  const [loading, setLoading] = useState<boolean>(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const options = [Option.VAT, Option.Blocked];

  const dispatch = useDispatch();

  const checkedItems = toArray(
    pickBy(items, (i: SelfBillListItem) => includes(checked, i.invoiceId))
  );

  const hasVatIssues =
    checkedItems.some((item) => item.vatStatusQuery) || false;
  const hasBlockedIssues = checkedItems.some((item) => item.blocked) || false;

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  };

  const handleSetOptions = (newOptions: any) => {
    setSelected((previousSetOptions) =>
      previousSetOptions.some((option) => option === newOptions)
        ? previousSetOptions.filter((option) => option !== newOptions)
        : [...previousSetOptions, newOptions]
    );
  };

  const getToolTipMessage = (option: Option) => {
    if (option === Option.VAT) {
      return "Set VAT Status override";
    } else if (option === Option.Blocked) {
      return "Set Blocked override";
    }
  };

  const disableOverride = (option: Option) => {
    if (option === Option.VAT) {
      return !hasVatIssues;
    } else if (option === Option.Blocked) {
      return !hasBlockedIssues;
    }
    return true;
  };

  const handleOverride = () => {
    setLoading(true);
    if (error) {
      setLoading(false);
      return;
    }

    const overrideBlocked = selected.includes(Option.Blocked);
    const overrideVatStatusQuery = selected.includes(Option.VAT);

    checkedItems.forEach((item) => {
      dispatch(
        updateSelfBillListOverride(item.invoiceId, {
          ...(item.blocked && overrideBlocked && { overrideBlocked: true }),
          ...(item.vatStatusQuery &&
            overrideVatStatusQuery && { overrideVatStatusQuery: true }),
        })
      );
    });

    setLoading(false);
    setSelected([]);
    setOpenOverrideModal(false);
  };

  return (
    <Box display="flex" justifyContent="flex-end" paddingBottom={1}>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="Button group for post invoice"
        disabled={checked.length === 0}
        size="large"
      >
        <Button onClick={() => setOpenPostInvoiceModal(true)}>
          Post Invoice
        </Button>
        <Button
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDown />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{ zIndex: 1 }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem key={index} dense={true}>
                      <Tooltip
                        title={getToolTipMessage(option)}
                        placement="left"
                      >
                        <FormControlLabel
                          disabled={disableOverride(option)}
                          control={
                            <Checkbox
                              checked={selected?.some((o) => o === option)}
                              onChange={() => handleSetOptions(option)}
                            />
                          }
                          label={option}
                        />
                      </Tooltip>
                    </MenuItem>
                  ))}
                  <Box display="flex" justifyContent="flex-start" p={1}>
                    <Box paddingRight={1}>
                      <Button
                        variant="outlined"
                        disabled={selected.length === 0}
                        onClick={() => setOpenOverrideModal(true)}
                      >
                        Apply
                      </Button>
                    </Box>
                  </Box>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <DraggableModal
        aria-describedby="confirm-override-modal"
        aria-labelledby="confirm-override-modal-title"
        open={openOverrideModal}
        onClose={() => setOpenOverrideModal(false)}
      >
        <form
          style={{ maxHeight: 600, overflowY: "auto" }}
          onSubmit={(e) => {
            e.stopPropagation();
            e.preventDefault();
            handleOverride();
          }}
        >
          <Card className={classes.card} id="confirm-override-modal-title">
            <APIFetchingWrapper loading={loading} error={error}>
              <Box mt={2} mb={4} justifyContent="center">
                <Typography
                  variant="h2"
                  align="center"
                  gutterBottom
                  id="confirm-override-modal"
                >
                  {"Confirm Override"}
                </Typography>
                <Typography
                  variant="h4"
                  align="center"
                  gutterBottom
                  id="confirm-override-modal"
                >
                  {"Are you sure you want to override these items?"}
                </Typography>
              </Box>
            </APIFetchingWrapper>
            <Box display="flex" flexDirection="row-reverse">
              <LoaderButton
                id="override-confirm-button"
                size="large"
                loading={loading}
                color="primary"
                variant="contained"
                type="submit"
              >
                Override
              </LoaderButton>
              <Button
                id="override-cancel-button"
                size="large"
                variant="outlined"
                onClick={() => setOpenOverrideModal(false)}
                disabled={loading}
                className={classes.buttonCancel}
              >
                Cancel
              </Button>
            </Box>
          </Card>
        </form>
      </DraggableModal>
      <DraggableModal
        aria-describedby="post-invoice-modal"
        aria-labelledby="post-invoice-modal-title"
        open={openPostInvoiceModal}
        onClose={() => setOpenPostInvoiceModal(false)}
      >
        <Card className={classes.card} id="post-invoice-modal-title">
          <Box mt={2} mb={4} justifyContent="center">
            <Typography
              variant="h2"
              align="center"
              gutterBottom
              id="post-invoice-modal"
            >
              {"Post Invoice"}
            </Typography>
          </Box>
        </Card>
      </DraggableModal>
    </Box>
  );
}
