import React from "react";
import { useDispatch } from "react-redux";
import { ModalDeleteWrapper } from "../../../shared/components/modal_field_controls/ModalDeleteWrapper";

import { DELETE_INVOICE } from "../../../store/selfBillList/types";

export const ModalDeleteInvoice = ({ rowData }: { rowData: any }) => {
  const dispatch = useDispatch();
  const { invoiceId } = rowData;

  return (
    <ModalDeleteWrapper
      label="Delete Self Bill Invoice"
      buttonName="Delete"
      message={() => (
        <p>
          Do you really wish to delete invoice id: <b>{invoiceId}</b>?
        </p>
      )}
      onDelete={() => {
        return new Promise((resolve) => {
          dispatch({
            type: DELETE_INVOICE,
            payload: {
              invoiceId: invoiceId,
            },
          });
        });
      }}
      id="delete-self-bill-invoice-id"
    />
  );
};
