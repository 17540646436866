import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataTable } from "../../../shared/components/DataTable";
import { Button, Checkbox } from "@mui/material";
import { FiltersWrapper } from "../../../shared/components/filter_controls/FiltersWrapper";
import { useQueryParams, withDefault } from "use-query-params";
import { AppliedFilters } from "../../../shared/components/filter_controls/AppliedFilters";
import { MultiSelect } from "../../../shared/components/filter_controls/MultiSelect";
import { DateRangeControl } from "../../../shared/components/filter_controls/DateRangeControl";
import {
  CommaDelimitedArrayParam,
  dateRangeParser,
} from "../../../shared/helpers/queryParsing";
import { RootState } from "../../../store/rootReducer";
import {
  QuerySelfBillListFilterParams,
  SelfBillListApiItem,
} from "../../../api/invoice/self-bill-list";
import { TableAPIRequestParams } from "../../../api/types";
import {
  requestSelfBillList,
  setSelfBillListItemChecked,
} from "../../../store/selfBillList/actions";
import { formatCurrencyGBP } from "../../../shared/helpers/currency";
import { formatBooleanAsIcon } from "../../../shared/helpers/boolean";
import { PaymentTerms } from "../../../shared/enum/invoice";
import { ModalDateUpdate } from "./ModalDateUpdate";
import { formatISOForDisplay } from "./../../../shared/helpers/dates";
import { MultiTextField } from "../../../shared/components/filter_controls/MultiTextField";
import { useHistory } from "react-router-dom";
import OverrideMenu from "./OverrideMenu";
import { ModalDeleteInvoice } from "./ModalDeleteInvoice";

export function SelfBillList() {
  const { items, fetching, error, count, checked } = useSelector(
    (state: RootState) => state.selfBillList
  );
  const dispatch = useDispatch();

  const [filters, updateFilters] = useQueryParams({
    territoryCode: withDefault(CommaDelimitedArrayParam, []),
    datePosted: dateRangeParser,
    partnerCode: withDefault(CommaDelimitedArrayParam, []),
    postingIssue: withDefault(CommaDelimitedArrayParam, []),
  });

  const history = useHistory();

  const [tableControls, setTableControls] = useState<
    TableAPIRequestParams<SelfBillListApiItem>
  >({
    pageNumber: 1,
    pageSize: 25,
    sortDirection: 1,
    sortBy: "invoiceId",
  });

  const filterMeta = [
    {
      name: "territoryCode",
      label: "Territory Code",
    },
    {
      name: "datePosted",
      label: "Posting/Document Date",
      isDateRange: true,
    },
    {
      name: "partnerCode",
      label: "Partner Code",
    },
    {
      name: "postingIssue",
      label: "Posting Issue",
    },
  ];

  const cleanseRequestSelfBillListData = (
    filters: any
  ): QuerySelfBillListFilterParams => {
    const postingIssue = filters["postingIssue"];
    const { postingIssue: _, ...restFilters } = filters;

    return {
      ...restFilters,
      partnerVatStatusQuery: postingIssue.includes("VAT Status")
        ? [true]
        : undefined,
      partnerBlocked: postingIssue.includes("Blocked") ? [true] : undefined,
      // Add Posting Date whenever possible
    };
  };

  useEffect(() => {
    dispatch(
      requestSelfBillList({
        tableControls: tableControls,
        filterParams: cleanseRequestSelfBillListData(filters),
      })
    );
  }, [dispatch, tableControls, filters]);

  const handleResetAllFilters = () => {
    updateFilters(
      Object.keys(filters).reduce(
        (acc, cur) => ({ ...acc, [cur]: undefined }),
        {}
      )
    );
  };

  const [open, setOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [currentInvoiceId, setCurrentInvoiceId] = useState<number | null>(null);

  const handleDateClick = (date: string, invoiceId: number) => {
    setSelectedDate(new Date(date));
    setCurrentInvoiceId(invoiceId);
    setOpen(true);
  };

  const handleInvoiceIdClick = (invoiceId: number) => {
    history.push({
      pathname: `asset-changes`,
      state: {
        invoiceId: invoiceId,
      },
    });
  };

  const handleModalClose = () => {
    setOpen(false);
  };

  const handleSaveDate = () => {
    setSelectedDate(null);
    setCurrentInvoiceId(null);
    dispatch(
      requestSelfBillList({
        tableControls: tableControls,
        filterParams: filters,
      })
    );
  };

  const actions = [
    {
      label: "Delete",
      renderComponent: (row: any) => <ModalDeleteInvoice rowData={row} />,
    },
  ];

  const CustomCheckbox = (_: string, rowData: SelfBillListApiItem) => {
    return (
      <Checkbox
        color="primary"
        data-cy={`filter-item-checkbox-${rowData.invoiceId}`}
        checked={checked.includes(rowData.invoiceId)}
        onChange={(e) =>
          dispatch(
            setSelfBillListItemChecked(rowData.invoiceId, e.target.checked)
          )
        }
      />
    );
  };

  let columns: {
    [key: string]: any;
    name: keyof SelfBillListApiItem;
    label: string;
    customBodyRender?: (
      input: string | number | boolean | null,
      rowData: any
    ) => React.ReactNode;
    align?: "left" | "right" | "center";
  }[] = [
    {
      name: "checked",
      label: "",
      align: "left",
      customBodyRender: (val, rowData) =>
        CustomCheckbox(val as string, rowData),
    },
    {
      name: "invoiceId",
      label: "SB No.",
      align: "left",
      customBodyRender: (val) => {
        const id = `asset-changes-${val}`;
        return (
          <Button id={id} onClick={() => handleInvoiceIdClick(val as number)}>
            {val}
          </Button>
        );
      },
    },
    { name: "reference", label: "Reference", align: "left" },
    {
      name: "datePosted",
      label: "Posting/Document Date",
      align: "left",
      customBodyRender: (val, rowData) => {
        const id = `posting-document-date-update-${rowData.invoiceId}`;
        return (
          <Button
            id={id}
            onClick={() => handleDateClick(val as string, rowData.invoiceId)}
          >
            {formatISOForDisplay(val as string)}
          </Button>
        );
      },
    },
    {
      name: "paymentTerms",
      label: "Payment Terms",
      align: "left",
      customBodyRender: (val) => PaymentTerms[val as keyof typeof PaymentTerms],
    },
    { name: "partnerCode", label: "Partner Code", align: "left" },
    { name: "vatRegNo", label: "VAT Number", align: "left" },
    {
      name: "amountExcVat",
      label: "Amount (Ex VAT)",
      align: "right",

      customBodyRender: (val) => formatCurrencyGBP((val as number | null) || 0),
    },
    {
      name: "amountIncVat",
      label: "Amount (Inc VAT)",
      align: "right",
      customBodyRender: (val) => formatCurrencyGBP((val as number | null) || 0),
    },
    { name: "territoryCode", label: "Territory Code", align: "left" },
    {
      name: "vatStatusQuery",
      label: "VAT Status",
      align: "center",
      customBodyRender: (val) => {
        const booleanVal =
          val === "true" ? true : val === "false" ? false : val;
        return formatBooleanAsIcon(booleanVal as boolean);
      },
    },
    {
      name: "blocked",
      label: "Blocked",
      align: "center",
      customBodyRender: (val) => {
        const booleanVal =
          val === "true" ? true : val === "false" ? false : val;
        return formatBooleanAsIcon(booleanVal as boolean);
      },
    },
    {
      name: "blocked", // Correct name once CM-5310 is completed https://giacom.atlassian.net/browse/CM-5310
      label: "Posting Date",
      align: "center",
      customBodyRender: (val) => {
        const booleanVal =
          val === "true" ? true : val === "false" ? false : val;
        return formatBooleanAsIcon(booleanVal as boolean);
      },
    },
  ];

  return (
    <React.Fragment>
      <OverrideMenu />
      <FiltersWrapper
        onResetAll={handleResetAllFilters}
        countLabel={items.length > 0 ? count : undefined}
        allowHidden={true}
        controls={() => (
          <React.Fragment>
            <MultiTextField
              type="text"
              name="Territory Code"
              id="territory-code-filter-control"
              selected={filters.territoryCode || []}
              onChange={(territoryCode) => updateFilters({ territoryCode })}
            />
            <DateRangeControl
              name="Posting/Document Date"
              id="posting-date-filter-control"
              selected={filters.datePosted || [null, null]}
              onChange={(datePosted) => updateFilters({ datePosted })}
            />
            <MultiTextField
              type="text"
              name="Partner Code"
              id="posting-code-filter-control"
              selected={filters.partnerCode || []}
              onChange={(partnerCode) => updateFilters({ partnerCode })}
            />
            <MultiSelect
              name="Posting Issue"
              id="posting-issue-filter-control"
              options={["VAT Status", "Blocked", "Posting Date"]}
              selected={filters.postingIssue || []}
              onChange={(postingIssue) => updateFilters({ postingIssue })}
            />
          </React.Fragment>
        )}
        chips={() => (
          <AppliedFilters
            filters={filters as QuerySelfBillListFilterParams}
            meta={filterMeta}
            updateFilters={updateFilters}
          />
        )}
      />
      <DataTable
        title="Self Bill List"
        stickyHeader
        limitHeight
        error={error}
        hover
        loading={fetching}
        data={items}
        columns={columns}
        rowsPerPageOptions={[25, 50, 100]}
        recordsCount={count}
        onTableControlsChange={(tableControls) => {
          setTableControls(tableControls);
        }}
        tableControls={tableControls}
        allowColumnControl={true}
        hasActions={true}
        actions={actions}
        rowIdKey="invoiceId"
      />
      <ModalDateUpdate
        open={open}
        onClose={handleModalClose}
        onSave={handleSaveDate}
        date={selectedDate}
        invoiceId={currentInvoiceId}
        tableControls={tableControls}
        filterParams={filters}
        cleanseRequestSelfBillListData={cleanseRequestSelfBillListData}
      />
    </React.Fragment>
  );
}
