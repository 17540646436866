import { affinityTheme } from "./shared/theme";
import {
  CssBaseline,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material";
import React from "react";
import { AuthProvider } from "oidc-react";
import { Layout } from "./layout";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Assets } from "./views/assets";
import { NavMenu } from "./nav";
import { ReduxProvider } from "./store/ReduxProvider";
import { QueryParamProvider } from "use-query-params";
import { Receivables } from "./views/receivables";
import { SelfBill } from "./views/self-bill";
import { NoMatch } from "./nav/components/NoMatch";
import { Products } from "./views/products";
import authConfig from "./authConfig";
import { AuthLoading } from "./shared/components/AuthLoading";
import { RestrictedRoute } from "./shared/components/RestrictedRoute";
import { Permissions } from "./store/permissions/types";
import { RootRedirect } from "./shared/components/RootRedirect";
import { AssetFinancialsReport } from "./views/report/assets/AssetFinancialsReport";
import reactUseCookie from "react-use-cookie";
import { InterfaceReport } from "./views/report/processing/interface_report";
import { DeferredCostReport } from "./views/report/financial/DeferredCostReport";
import { Partners } from "./views/partners";
import { PortAndResignReport } from "./views/report/assets/PortAndResignReport";
import { UnraisedRevshareReport } from "./views/report/financial/UnraisedRevshareReport";
import { DisconRecUpValueReport } from "./views/report/financial/DisconRecUpValueReport";
import { OutstandingCommissionsReport } from "./views/report/financial/OutstandingCommissionsReport";
import { PrimaryProductReport } from "./views/report/products/PrimaryProductReport";
import { SecondaryProductReport } from "./views/report/products/SecondaryProductReport";
import { MPNLevelAssetReport } from "./views/report/assets/MPNLevelAssetReport";
import { ProgressReport } from "./views/report/processing/progress_report";
import { PartnerRateAdjustmentReport } from "./views/report/partners/PartnerRateAdjustmentReport";
import { PartnerAdminInformationReport } from "./views/report/partners/PartnerAdminInformationReport";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { SpendCapReport } from "./views/report/products/SpendCapReport";
import { ConnectionCommissionsForAccrualsReport } from "./views/report/account/ConnectionCommissionsForAccrualsReport";
import { InvoiceLines } from "./views/self-bill/invoice-lines/InvoiceLines";

function App() {
  const [cookie, setCookie] = reactUseCookie("cookie", window.location.href);

  return (
    <AuthProvider
      {...authConfig}
      onBeforeSignIn={() => {
        setCookie(window.location.href);
      }}
      onSignIn={() => {
        window.location.href = cookie;
      }}
    >
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={affinityTheme}>
          <CssBaseline />
          <ReduxProvider>
            <AuthLoading>
              <Router>
                <QueryParamProvider ReactRouterRoute={Route}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Layout nav={<NavMenu />}>
                      <Switch>
                        <Route exact path="/">
                          <RootRedirect />
                        </Route>
                        <Route
                          exact
                          path="/self-bill/asset-changes"
                          component={InvoiceLines}
                        />
                        <RestrictedRoute
                          permissions={[
                            Permissions.AssetsMenu,
                            Permissions.ReadAssets,
                          ]}
                          path="/assets"
                        >
                          <Assets />
                        </RestrictedRoute>
                        <RestrictedRoute
                          permissions={[Permissions.ProductsMenu]}
                          path="/products"
                        >
                          <Products />
                        </RestrictedRoute>
                        <Route path="/partners">
                          <Partners />
                        </Route>
                        <RestrictedRoute
                          permissions={[Permissions.ReceivableMenu]}
                          path="/receivables"
                        >
                          <Receivables />
                        </RestrictedRoute>
                        <RestrictedRoute
                          permissions={[Permissions.PayableMenu]}
                          path="/self-bill"
                        >
                          <SelfBill />
                        </RestrictedRoute>
                        <Route path="/financials-report">
                          <AssetFinancialsReport />
                        </Route>
                        <Route path="/interface-report">
                          <InterfaceReport />
                        </Route>
                        <Route path="/partner-admin-information-report">
                          <PartnerAdminInformationReport />
                        </Route>
                        <Route path="/partner-rate-adjustment-report">
                          <PartnerRateAdjustmentReport />
                        </Route>
                        <Route path="/deferred-cost-report">
                          <DeferredCostReport />
                        </Route>
                        <Route path="/outstanding-commissions-report">
                          <OutstandingCommissionsReport />
                        </Route>
                        <Route path="/port-and-resign-report">
                          <PortAndResignReport />
                        </Route>
                        <Route path="/unraised-revshare-report">
                          <UnraisedRevshareReport />
                        </Route>
                        <Route path="/discon-rec-up-value-report">
                          <DisconRecUpValueReport />
                        </Route>
                        <Route path="/primary-product-report">
                          <PrimaryProductReport />
                        </Route>
                        <Route path="/secondary-product-report">
                          <SecondaryProductReport />
                        </Route>
                        <Route path="/spend-cap-report">
                          <SpendCapReport />
                        </Route>
                        <Route path="/mpn-level-asset-report">
                          <MPNLevelAssetReport />
                        </Route>
                        <Route path="/connection-commission-for-accruals-report">
                          <ConnectionCommissionsForAccrualsReport />
                        </Route>
                        <Route path="/progress">
                          <ProgressReport />
                        </Route>
                        <Route path="*">
                          <NoMatch />
                        </Route>
                      </Switch>
                    </Layout>
                  </LocalizationProvider>
                </QueryParamProvider>
              </Router>
            </AuthLoading>
          </ReduxProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </AuthProvider>
  );
}

export default App;
