import React from "react";
import { CheckCircleOutline, ErrorOutline } from "@mui/icons-material";

export function formatBooleanAsIcon(value: boolean) {
  if (!value) {
    return <CheckCircleOutline color="success" fontSize="small" />;
  } else {
    return <ErrorOutline color="error" fontSize="small" />;
  }
}
