import {
  REQUEST_DATA,
  UPDATE_POSTED_DATE,
  SET_ERROR,
  SET_DATA,
  DELETE_INVOICE,
  SelfBillListState,
  SetBillListActionTypes,
  SET_ITEM_CHECKED,
  RESET_CHECKED_ITEMS,
  UPDATE_OVERRIDES,
} from "./types";

const initialState: SelfBillListState = {
  items: [],
  checked: [],
  count: 0,
  fetching: false,
};

export function selfBillListReducer(
  state = initialState,
  action: SetBillListActionTypes
): SelfBillListState {
  switch (action.type) {
    case REQUEST_DATA:
      return {
        ...state,
        error: undefined,
        fetching: true,
      };

    case UPDATE_POSTED_DATE:
      return {
        ...state,
        error: undefined,
        fetching: true,
      };

    case SET_ERROR:
      return {
        ...state,
        error: action.payload.err,
        fetching: false,
      };

    case SET_DATA:
      return {
        ...state,
        fetching: false,
        items: action.payload.data.items,
        count: action.payload.data.count,
      };

    case DELETE_INVOICE:
      return {
        ...state,
        error: undefined,
        fetching: true,
      };

    case SET_ITEM_CHECKED:
      const { checked, invoiceId } = action.payload;
      return {
        ...state,
        checked: checked
          ? [...state.checked, invoiceId]
          : state.checked.filter((c) => c !== invoiceId),
      };

    case RESET_CHECKED_ITEMS:
      return {
        ...state,
        checked: [],
      };

    case UPDATE_OVERRIDES:
      return {
        ...state,
        error: undefined,
        fetching: true,
      };

    default:
      return state;
  }
}
