import { request } from "..";
import {
  combine,
  stringifyFilterParams,
  stringifyTableParams,
} from "../../shared/helpers/querySerialisation";
import { NoteApiItem } from "../note";
import { PaginatedResult, TableAPIRequestParams } from "../types";
import { SelfBillToParnerApi } from "./self-bill-to-partner-code";

export interface PartnerApi {
  partnerId: number;
  thirdPartyId: string;
  partnerName: string;
}

export type QueryPartnerApiItem = {
  partnerId: number;
  thirdPartyId: string;
  partnerName: string;
  paymentTerms: number;
  partnerStatus: number;
  contactName: string;
  contactEmail: string;
  contactNumber: string;
};

export type QueryPartnersFilterParams = {
  partnerId: (number | null)[];
  thirdPartyId: (string | null)[];
  partnerName: (string | null)[];
  paymentTerms: (number | null)[];
  partnerStatus: (number | null)[];
  contactName: (string | null)[];
  contactEmail: (string | null)[];
  contactNumber: (string | null)[];
};

export type QueryPartnersRequestParams = {
  tableParams: TableAPIRequestParams<QueryPartnerApiItem>;
  filterParams: Partial<QueryPartnersFilterParams>;
};

export type PartnerServiceProvider = {
  readonly partnerServiceProviderId: number;
  readonly partnerId: number;
  readonly serviceProviderId: number;
  readonly serviceProviderName: string;
  readonly serviceProviderAccountTypeId: number;
  readonly serviceProviderAccountTypeName: string;
  readonly spDealerCode: string;
  readonly enabled: boolean;
};

export type OocRevenueShares = {
  partnerId: number;
  partnerServiceProviderOOCPaymentTermId: number;
  serviceProviderId: number;
  serviceProviderName: string;
  serviceProviderAccountTypeId: number;
  serviceProviderAccountTypeName: string;
  oocPaymentTermId: number;
  oocPaymentTermName: string;
  oocPaymentTermDescription: string;
  assetStartDateFrom: string;
  assetStartDateTo: string;
  permittedDaysOoc: number;
  unlimited: boolean;
};

export interface DetailPartnerApiItem extends QueryPartnerApiItem {
  bdm: string;
  ibdm: string;
  region: string;
  territory: string;
  partnerServiceProviders: PartnerServiceProvider[];
  oocRevenueShares: OocRevenueShares[];
  rates: PartnerRate[];
  notes: NoteApiItem[];
  blocked: boolean;
  doNotSelfBill: boolean;
  vatRegNo: string;
  vatStatusQuery: boolean;
  selfBillToPartner: SelfBillToParnerApi;
}

export interface DetailPartnerGeneralInfo {
  partnerId: number;
  doNotSelfBill: boolean;
}

export type PartnerRate = {
  partnerRateAdjustmentId: number;
  serviceProviderId: number;
  tier: number;
  receivedBillRate: number;
  standardRate: number;
  startDate: string;
  endDate: string;
  adjustment: number;
  adjStartDate: string;
  adjEndDate: string;
  productType: string;
};

export function getPartnerDetail(partnerId: number) {
  return request<DetailPartnerApiItem>(`partner/${partnerId}`);
}

export function getAllPartners() {
  return request<PartnerApi[]>("partner/getallpartners");
}

export function getQueryPartners(params: QueryPartnersRequestParams) {
  const tableParamsQS = stringifyTableParams(params.tableParams);
  const filterParamsQS = stringifyFilterParams(params.filterParams);

  return request<PaginatedResult<QueryPartnerApiItem>>(
    `partner/query${combine([tableParamsQS, filterParamsQS])}`
  );
}
