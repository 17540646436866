import React, { useState, useEffect } from "react";
import { Button, Card, Typography, FormControl, Box } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { DraggableModal } from "../../../shared/components/DraggableModal";
import { LoaderButton } from "../../../shared/components/LoaderButton";
import { APIFetchingWrapper } from "../../../shared/components/APIFetchingWrapper";
import { makeStyles } from "tss-react/mui";
import { RequestError } from "../../../api/types";
import { useDispatch } from "react-redux";
import { setUpdateDatePosted } from "../../../store/selfBillList/actions";
import { TableAPIRequestParams } from "../../../api/types";
import {
  QuerySelfBillListFilterParams,
  SelfBillListApiItem,
} from "../../../api/invoice/self-bill-list";
import { requestSelfBillList } from "../../../store/selfBillList/actions";

const useStyles = makeStyles()((theme) => ({
  card: {
    padding: theme.spacing(2),
    width: 500,
  },
  buttonCancel: {
    marginRight: theme.spacing(0.5),
  },
}));

interface ModalDateUpdateProps {
  open: boolean;
  onClose: () => void;
  onSave: (date: Date | null) => void;
  date: Date | null;
  invoiceId: number | null;
  tableControls: TableAPIRequestParams<SelfBillListApiItem>;
  filterParams: QuerySelfBillListFilterParams;
  cleanseRequestSelfBillListData: (
    filters: any
  ) => QuerySelfBillListFilterParams;
}

export function ModalDateUpdate({
  open,
  onClose,
  onSave,
  date,
  invoiceId,
  tableControls,
  filterParams,
  cleanseRequestSelfBillListData,
}: ModalDateUpdateProps) {
  const { classes } = useStyles();
  const [selectedDate, setSelectedDate] = useState<Date | null>(date);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<RequestError | undefined>();
  const [postedDate, setPostedDate] = useState<Date | null>(date);
  const [currentInvoiceId, setCurrentInvoiceid] = useState<number | null>(
    invoiceId
  );
  const dispatch = useDispatch();

  useEffect(() => {
    setSelectedDate(date);
    setPostedDate(date);
    setCurrentInvoiceid(invoiceId);
  }, [date, open, invoiceId]);

  const handleSaveClick = () => {
    setLoading(true);
    if (error) {
      setLoading(false);
      return;
    }
    if (selectedDate === postedDate) {
      setError({
        type: "client",
        message:
          "Selected date is already set. Please select a different date.",
      });
      setLoading(false);
      return;
    }
    setError(undefined);
    dispatch(
      setUpdateDatePosted(currentInvoiceId ?? 0, selectedDate ?? new Date())
    );
    dispatch(
      requestSelfBillList({
        tableControls,
        filterParams: cleanseRequestSelfBillListData(filterParams),
      })
    );
    setLoading(false);
    onSave(selectedDate);
    onClose();
  };

  return (
    <DraggableModal
      aria-describedby="date-picker-modal"
      aria-labelledby="date-picker-modal-title"
      open={open}
      onClose={() => {
        setError(undefined);
        onClose();
      }}
    >
      <form
        style={{ maxHeight: 600, overflowY: "auto" }}
        onSubmit={(e) => {
          e.stopPropagation();
          e.preventDefault();
          handleSaveClick();
        }}
      >
        <Card className={classes.card} id="date-picker-modal-title">
          <Box mt={2} mb={4}>
            <Typography
              variant="h2"
              align="center"
              gutterBottom
              id="date-picker-modal"
            >
              {"Select Posting/Document Date"}
            </Typography>
            <Box display="flex" justifyContent="center">
              <APIFetchingWrapper loading={loading} error={error}>
                <FormControl variant="outlined" size="small" fullWidth>
                  <DatePicker
                    label="Posting/Document Date"
                    format="dd/MM/yyyy"
                    value={selectedDate}
                    onChange={(value, context) => {
                      if (context.validationError) {
                        setError({
                          type: "client",
                          message:
                            "Invalid date format. Please select a valid date.",
                        });
                        return;
                      }
                      setSelectedDate(value);
                      setError(undefined);
                    }}
                  />
                </FormControl>
              </APIFetchingWrapper>
            </Box>
          </Box>
          <Box display="flex" flexDirection="row-reverse">
            <LoaderButton
              id="posting-document-date-save-button"
              size="large"
              loading={loading}
              color="primary"
              variant="contained"
              type="submit"
            >
              {"Save"}
            </LoaderButton>
            <Button
              id="posting-document-date-cancel-button"
              disabled={loading}
              size="large"
              variant="outlined"
              onClick={() => {
                setError(undefined);
                onClose();
              }}
              className={classes.buttonCancel}
            >
              Cancel
            </Button>
          </Box>
        </Card>
      </form>
    </DraggableModal>
  );
}
