import {
  SelfBillListApiItem,
  SelfBillListOverrideParams,
  SelfBillListParams,
} from "../../api/invoice/self-bill-list";
import { PaginatedResult, RequestError } from "../../api/types";
import {
  REQUEST_DATA,
  UPDATE_POSTED_DATE,
  SET_DATA,
  SET_ERROR,
  DELETE_INVOICE,
  SET_ITEM_CHECKED,
  RESET_CHECKED_ITEMS,
  SetDataAction,
  SetItemCheckedAction,
  ResetCheckedItemsAction,
  UPDATE_OVERRIDES,
} from "./types";

export function requestSelfBillList(params: SelfBillListParams) {
  return {
    type: REQUEST_DATA,
    payload: {
      params,
    },
  };
}

export function setSelfBillListData(
  data: PaginatedResult<SelfBillListApiItem>
): SetDataAction {
  return {
    type: SET_DATA,
    payload: {
      data,
    },
  };
}

export function setUpdateDatePosted(invoiceId: number, datePosted: Date) {
  return {
    type: UPDATE_POSTED_DATE,
    payload: {
      invoiceId,
      datePosted,
    },
  };
}

export function setUpdateDatePostedError(err: RequestError) {
  return {
    type: SET_ERROR,
    payload: {
      err,
    },
  };
}

export function setSelfBillListError(err: RequestError) {
  return {
    type: SET_ERROR,
    payload: {
      err,
    },
  };
}

export function deleteSelfBillInvoice(invoiceId: number) {
  console.log(`delete action : ${invoiceId}`);
  return {
    type: DELETE_INVOICE,
    payload: {
      invoiceId,
    },
  };
}

export function setSelfBillListItemChecked(
  invoiceId: number,
  checked: boolean
): SetItemCheckedAction {
  return {
    type: SET_ITEM_CHECKED,
    payload: {
      invoiceId,
      checked,
    },
  };
}

export function resetSelfBillListItemsChecked(): ResetCheckedItemsAction {
  return {
    type: RESET_CHECKED_ITEMS,
  };
}

export function updateSelfBillListOverride(
  invoiceId: number,
  params: SelfBillListOverrideParams
) {
  return {
    type: UPDATE_OVERRIDES,
    payload: {
      invoiceId,
      params,
    },
  };
}

export function setUpdateSelfBillListOverrideError(err: RequestError) {
  return {
    type: SET_ERROR,
    payload: {
      err,
    },
  };
}
